var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-transactions-table"
  }, [_c('sygni-container-title', [_vm._v("Transactions")]), _c('div', {
    staticClass: "portfolio-transactions-table__inner"
  }, [_c('div', {
    staticClass: "portfolio-transactions-table__body"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-inner-table",
    attrs: {
      "innertable": "true",
      "fields": _vm.innerTableFields
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(transactionPartyName)",
      fn: function fn(rowData) {
        return [_c('p', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.transactionPartyName),
            expression: "getTooltipMessage(rowData.item.transactionPartyName)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "table__company"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.transactionPartyName, 35)))])])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(rowData.item.seriesOfSharesName ? rowData.item.seriesOfSharesName : 'N/A') + " ")];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.instrumentCount)))])])];
      }
    }, {
      key: "cell(counterFrom)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(rowData.item.counterFrom ? _vm.$options.filters.thousandSeparator(rowData.item.counterFrom) : 'N/A'))])])];
      }
    }, {
      key: "cell(counterTo)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(rowData.item.counterTo ? _vm.$options.filters.thousandSeparator(rowData.item.counterTo) : 'N/A'))])])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.nominalValue.value, 2)) + " " + _vm._s(rowData.item.nominalValue.currency))])])];
      }
    }, {
      key: "cell(contribution)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.contribution.value, 2)) + " " + _vm._s(rowData.item.contribution.currency))])])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }